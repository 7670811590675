
// @import "bootstrap";

$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
@import "~flatpickr/dist/flatpickr.css";
@import "~tom-select/src/scss/tom-select.bootstrap5";

.fs-xs{
  font-size: $font-size-base * 0.7 !important;
}

.jumbotron {
  padding: $spacer * 2 $spacer;
  margin-bottom: $spacer * 2;
  background-color: $gray-200;
  border-radius: $border-radius-lg;
}

@include media-breakpoint-up(sm) {
  .jumbotron {
      padding: $spacer * 4 $spacer * 2;
  }
}

.flatpickr-mobile + .btn.input-group-append {
  display:none;
}

.flatpickr-input {
  position: absolute !important;
  visibility: hidden;
  left: 1px;
  z-index: -1;
}

.ts-dropdown,
.ts-input {
  .ts-description {
    color: $select-color-optgroup-text;
    font-size: $font-size-base * 0.6;
    position: absolute;
    bottom: -$font-size-base * 0.04;
  }
}


.ts-dropdown .option {
  position: relative;
  padding-bottom: 7px;
}

.ts-input,
.ts-control.single .ts-input.input-active {
  cursor: pointer !important;
}

.ts-input .item {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  display:block;
}

.ts-input-date-format {
  width: calc(100% - 31px);
  
  [role="combobox"] {
      display: none !important;
  }
}

#__next {
  height: 100%;
}

.main {
  flex: 1 0 auto;
}

.logo:hover {
  text-decoration:none;
  opacity: 0.8;
}

.output-wrapper[aria-hidden="true"] {
  opacity: 0.25;
  pointer-events: none;
}